
body{
    font-family: 'Roboto', sans-serif;
}

.f-14{
    font-size: 14px;
}

.paletteGreen {
  background-color: #E5F6EE !important;
}

.paletteRed{
    background-color: #FAE7E9 !important;
}

.bg-login {
	background-size: cover;
}

.header {
	height: 50px;
	background-size: cover;
}

.fh {
    height: calc(100vh - 50px);
}

.border-select {
	border-bottom: 10px solid #7952B3;
	border-width: 5px;
}

.rotate-90 {
  transform: rotate(90deg);
}

/* Bootstrap changes */

.disabled {
    opacity: 0.25;
    pointer-events: none;
}

button:disabled{
    opacity: 0.25 !important;
    pointer-events: none !important;
}

thead, tbody, tfoot, tr, td, th {
    border-block-color: lightgrey;
}

td.strike {
    background-color: #FFEEEE;
}

.dropdown-item {
    cursor: pointer;
}

.focus-none:focus{
    box-shadow: none;
}

/* Navbar */

.navigation-menu {
    color: white;
    background-color: #23282D;
    height: calc(100vh - 50px);
}

.selected-navbar {
    background-color: white;
    color: black !important;
    pointer-events: none;
}

.selected-navbar:hover {
    background-color: white;    
}

.unselected-navbar{
    color: white;
    line-height: 1,5;
    margin-right: 1.5rem;
    padding: 12px;
    margin-block-end: 0em;
    text-decoration: none;
}

.unselected-navbar:hover{
    background-color: #32373C;
    color: white;
}


/* Scrollbar */

.pnb-scroll {
  height: calc(100vh - 200px);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white; 
  border: 1px solid #474747;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #474747; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5E5E5E; 
}

/*History and Stats*/

.civilisations {
  color: #707070;
  display: block;
  font-size: 12px;
}